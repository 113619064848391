<template>
  <v-container fluid class="fill-height pa-0 white--text">
    <v-row
      v-if="loading"
      justify="center"
      style="z-index: 10"
      align="center"
      class="primary1 fill-height"
    >
      <loading-component />
    </v-row>
    <v-row v-else justify="center" align="center" class="fill-height">
      <v-col
        v-if="!isMobile"
        cols="12"
        sm="4"
        class="primary d-flex justify-center fill-height align-center"
        style="height: 100%"
      >
        <slot name="left"></slot>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        class="primary1 d-flex align-center fill-height"
        style="'height:100%'"
        :class="isMobile && 'justify-center'"
      >
        <div style="padding-left: 5%; padding-right: 5%">
          <slot name="right"></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((svm) => {
      vm.fromRoute = from;
    });
  },
  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromRoute: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {},
};
</script>

<style>
</style>