import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import { alert } from './modules/alert';
import { account } from './modules/account';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
  },
  plugins: [createPersistedState()],
});
